import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import './umeedword.css';
import { getAllConversation } from '../../core/api/apiCalls';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';

const ChatSidebar = () => {
    const location = useLocation(); // Get the current route location
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const [showConversations, setShowConversations] = useState(false);
    const [conversations, setConversations] = useState([null]);
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        return dayjs(dateString).format('MM/DD'); // 12-hour format
    };
    const toggleConversations = () => {
        setShowConversations(!showConversations);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const getConversation = async () => {

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;

            const res = await getAllConversation(userId);
            if (res.data.success) {
                setConversations(res?.data?.conversations)
            };

        } catch (error) {
            console.error('Failed to received chat history. Please try again.');
        }
    }

    useEffect(() => {
        // Call the API once immediately
        getConversation();
        const intervalId = setInterval(() => {
            getConversation();
        }, 10000); // 10 seconds   
        return () => clearInterval(intervalId);  // Clean up the interval when the component is unmounted
    }, []);

    // Update isMobileView state based on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 600);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const HandleConversationId = (conversationIdOld) => {
        navigate(`/previous-chat`, { state: { conversationIdOld } });
    };
    

    // Determine if a path is selected based on the current route
    const isSelected = (path) => location.pathname === path;

    // Determine the style for a selected item
    const getItemStyle = (path) => ({
        backgroundColor: isSelected(path) ? '#e0e0e0' : 'transparent', // Change the color as needed
        padding: '10px',
        borderRadius: '5px',
    });
    const getItemStyleHistory = (path, conversationIdOld) => ({
        backgroundColor: conversationIdOld  ? '#e0e0e0' : 'transparent',
        padding: '5px',
        borderRadius: '5px',
    });

    const letters = ['L', 'O', 'G', 'O'];
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : {};
    const UserType = user.role;
    return (
        <>
            {/* Sidebar toggle button for mobile view */}
            {isMobileView && (
                <IconButton className="sidebar-toggle" onClick={toggleSidebar}>
                    <MenuIcon />
                </IconButton>
            )}

            <div className={`chat-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div style={{ padding: '10px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                    <div className="umeedWord">
                        {letters.map((letter, index) => (
                            <span key={index} className="umeedLetter" style={{ animationDelay: `${index * 0.3}s` }}>
                                {letter}
                            </span>
                        ))}
                    </div>
                </div>
                <nav style={{ padding: '20px' }}>
                    <ul style={{ listStyle: 'none', padding: 0, marginTop: '-11px' }}>
                        {UserType === '1' && (
                            <li style={{ marginBottom: '10px' }}>
                                <Link to="/settings" style={{ ...linkStyle, ...getItemStyle('/settings') }}>
                                    <IconButton><SettingsIcon /></IconButton>
                                    Settings
                                </Link>
                            </li>
                        )}
                        {UserType === '0' && (
                            <>
                                <li style={{ marginBottom: '10px' }}>
                                    <Link to="/chat-message" style={{ ...linkStyle, ...getItemStyle('/chat-message') }}>
                                        <IconButton><ChatIcon /></IconButton>
                                        New Chat
                                    </Link>
                                </li>
                                <li style={{ marginBottom: '10px' }}>
                                    <Link to="/profile" style={{ ...linkStyle, ...getItemStyle('/profile') }}>
                                        <IconButton><AccountCircleIcon /></IconButton>
                                        Profile
                                    </Link>
                                </li>
                                {/* Toggle for conversation history */}
                                <li style={{ marginBottom: '2px' }}>
                                    <Link style={{ ...linkStyle, }} onClick={toggleConversations}>
                                        <IconButton><FilterListIcon /></IconButton>
                                        Show Conversations
                                    </Link>
                                </li>

                                {/* Conditional rendering of conversations */}
                                {showConversations && (
                                    <ul style={{ listStyleType: 'none', padding: '2px', fontSize: '8px' }}>
                                        {conversations?.map((conversation, index) => (
                                            <li
                                                key={conversation?.conversation_id}
                                                style={{
                                                    ...getItemStyleHistory('/previous-chat', conversation?.conversation_id), // Apply styles from getItemStyle
                                                    marginBottom: '8px',
                                                    fontSize: '12px',
                                                    color: '#636161',
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    cursor: 'pointer',
                                                    marginLeft: '18px',
                                                }}
                                                onClick={() => HandleConversationId(conversation?.conversation_id)}  // Send conversation_id to the function
                                            >
                                                <strong>{conversation?.title}</strong> - {formatDate(conversation?.created_at)}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {/* <li style={{ marginBottom: '10px' }}>
                                    <Link to="/chat-report" style={{ ...linkStyle, ...getItemStyle('/chat-report') }}>
                                        <IconButton><ReportIcon /></IconButton>
                                        Report
                                    </Link>
                                </li> */}
                                {/* <li style={{ marginBottom: '10px' }}>
                                    <Link to="/privacy-policy" style={{ ...linkStyle, ...getItemStyle('/privacy-policy') }}>
                                        <IconButton><PrivacyTipIcon /></IconButton>
                                        Privacy & Policy
                                    </Link>
                                </li> */}
                            </>
                        )}
                    </ul>
                </nav>
            </div>
        </>
    );
};

// Styles for the links
const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
};

export default ChatSidebar;
