import React, { useState } from 'react';
import { Input } from 'react-chat-elements';
import { Button, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
const ChatInput = ({ onSend }) => {
    const [message, setMessage] = useState('');

    const handleSend = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');
        }
    };

    return (
        <>
            <Box style={{ display: 'flex', padding: '10px', backgroundColor: '#ffffff', borderTop: '1px solid #ccc' }}>
                <Input
                    style={{ fontSize: '18px' }}
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                />
                <SendIcon style={{ color: '#4e4b4b', fontSize: '24px', marginLeft: '-34px', cursor: 'pointer',  marginTop: "8px", }} onClick={handleSend} />
            </Box>
            
        </>
    );
};

export default ChatInput;
