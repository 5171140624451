import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import TermAndCondition from './components/TermsAndConditions';
import GoogleCallback from './components/GoogleCallback';
import SignUp from './components/SignUp';
import Unauthorized from './components/unauthorized/unauthorized ';
import ChatMessage from './components/ChatModule/ChatMessage';
import PreviousChat from './components/ChatModule/PreviousChat';
import UserFormModal from './components/UserProfiling/UserFormModal';
import MoodTrackerModal from './components/MoodTrackerModal/MoodTrackerModal';
import UmeedAnimation from './components/UmeedAnimation/UmeedAnimation';
import Profile from './components/ChatModule/Profile';
import Report from './components/ChatModule/Report';
import Settings from './components/ChatModule/Settings';
import PrivacyPolicy from './components/ChatModule/PrivacyPolicy';
import { useSelector } from 'react-redux';

const RoutesComponent = () => {
    const { isAuthenticated, user } = useSelector((state) => state.auth);

    const PrivateRoute = ({ children, requiredUserType }) => {
        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }

        if (requiredUserType && user?.type !== requiredUserType) {
            return <Navigate to="/unauthorized" />;
        }

        return children;
    };

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<UmeedAnimation />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/TermAndCondition" element={<TermAndCondition />} />
                    <Route path="/google-callback" element={<GoogleCallback />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/profiling" element={<UserFormModal />} />
                    <Route path="/mood-tracker" element={<MoodTrackerModal />} />
                    <Route path="/chat-message" element={<ChatMessage />} />
                    <Route path="/previous-chat" element={<PreviousChat />} />
                    <Route path="/chat-Report" element={<Report />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </Router>
        </>

    );
};

export default RoutesComponent;
