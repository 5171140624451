import React, { useState } from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Autocomplete,
    Button,
    CircularProgress
} from "@mui/material";
import axios from "axios";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import Umeed from '../../Public/Umeed.jpg';
import { ProfilingData } from '../../core/api/apiCalls';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
        xs: '85%',   // Mobile view
        sm: '70%',   // Small screens
        md: '55%',   // Medium screens
        lg: '35%',   // Large screens
    },
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    border: "2px solid transparent",
};

const occupations = [
    "Doctor", "Lawyer", "Engineer", "Teacher", "Nurse", "Accountant",
    "Salesperson", "Customer Service Representative", "Chef", "Mechanic",
    "Firefighter", "Police Officer", "Architect", "Web Developer", "Writer",
    "Artist", "Musician", "Actor", "Dancer", "Designer", "Scientist", "Pilot",
    "Construction Worker", "Farmer", "Game Developer", "Student", "House Wife", "Other"
];

const ageOptions = Array.from({ length: 85 }, (_, i) => `${16 + i}`);
const genderOptions = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
];
const maritalStatusOptions = ["Single", "Married", "In-Relationship", "Divorce", "Widow"];

const UserFormModal = () => {

    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        age: "",
        gender: "",
        occupation: "",
        marital_status: "",
        location: ""
    });



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAutocompleteChange = (event, value, field) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const userEmail = user.email;
            const res = await ProfilingData({ ...formData, user_id: userId, email: userEmail });
            if (res.data.success) {
                toast.success(res.data.message);
                navigate('/mood-tracker');
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors; 
                for (const [key, messages] of Object?.entries(errorMessages)) {
            
                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} >
            <Box sx={style}>
                <Typography variant="h5" align="center" gutterBottom>
                    <img src={Umeed} alt="Umeed" style={{ width: 100, height: 100, borderRadius: '50px' }} />
                </Typography>
                <TextField
                    label="Name"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                />
                <Autocomplete
                    options={ageOptions}
                    value={formData?.age}
                    onChange={(event, value) => handleAutocompleteChange(event, value, "age")}
                    renderInput={(params) => <TextField {...params} label="Age" />}
                    fullWidth
                />
                <Autocomplete
                    options={genderOptions}
                    getOptionLabel={(option) => option.label}
                    value={genderOptions.find(option => option.value === formData?.gender) || null} // Ensure correct selection
                    onChange={(event, value) => handleAutocompleteChange(event, value?.value, "gender")} // Send value (M, F)
                    renderInput={(params) => <TextField {...params} label="Gender" />}
                    fullWidth
                />
                <Autocomplete
                    options={occupations}
                    value={formData?.occupation}
                    onChange={(event, value) => handleAutocompleteChange(event, value, "occupation")}
                    renderInput={(params) => <TextField {...params} label="Occupation" />}
                    fullWidth
                />
                <Autocomplete
                    options={maritalStatusOptions}
                    value={formData?.marital_status}
                    onChange={(event, value) => handleAutocompleteChange(event, value, "marital_status")}
                    renderInput={(params) => <TextField {...params} label="Marital Status" />}
                    fullWidth
                />
                <TextField
                    label="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    {loading ? <CircularProgress size={24} /> : "Continue"}
                </Button>
            </Box>
        </Modal>
    );
};

export default UserFormModal;
