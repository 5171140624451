import React, { useState, useEffect } from 'react';
import './UmeedAnimation.css';
import logo from '../../Public/Umeed.jpg'; 
import { useNavigate } from "react-router-dom";

const UmeedAnimation = () => {
    const navigate = useNavigate();
    const [fadeOut, setFadeOut] = useState(false); // Track when to fade out
    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeOut(true); // Start the fade-out animation
        }, 3500); // Trigger fade-out after 2.5 seconds

        const redirectTimer = setTimeout(() => {
            navigate('/login'); // Navigate to /login after fade-out is done
        }, 4500); // Redirect after 3.5 seconds to allow fade-out

        return () => {
            clearTimeout(timer); 
            clearTimeout(redirectTimer); 
        };
    }, [navigate]);


    const letters = ['L', 'O', 'G', 'O'];

    return (
        <div className={`umeed-wrapper ${fadeOut ? 'fade-out' : ''}`}>
            <div className="umeed-container">
                <div className="umeed-word">
                    {letters.map((letter, index) => (
                        <span key={index} className="umeed-letter" style={{ animationDelay: `${index * 0.3}s` }}>
                            {letter}
                        </span>
                    ))}
                </div>
                {/* <div className="umeed-logo">
                    <img src={logo} alt="Logo" style={{ width: 200, height: 200, borderRadius:'50px' }}  />
                </div> */}
            </div>
        </div>
    );
}

export default UmeedAnimation;
