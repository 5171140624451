import React, { useState, useEffect } from 'react';
import { MessageBox } from 'react-chat-elements';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatSidebar from '../Sidebar/ChatSidebar';
import 'react-chat-elements/dist/main.css';
import './ChatModule.css';
import toast from 'react-hot-toast';
import { chatMessageGPT,oldConversationsHistory } from '../../core/api/apiCalls';
import Umeed from '../../Public/Umeed.jpg';
import sarah from '../../Public/sarah.png';
import { useLocation,useParams  } from 'react-router-dom';


const PreviousChat = () => {
    const location = useLocation();
    const { conversationIdOld } = location.state || {};
    const [messages, setMessages] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);
    const [conversationId, SetConversationId] = useState(conversationIdOld || ''); // Use the passed conversationId
    const [loggedInUser] = useState({ name: 'John Doe', profileImage: '/path/to/image.jpg' });
    const [isTyping, setIsTyping] = useState(false);
    // Function to send message
    const handleSendMessage = async (message) => {
        // User's message
        const newMessage = {
            position: 'right', // Sent message on the right
            type: 'text',
            text: message,
            date: new Date(),
        };

        // Update UI immediately with user's message
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            setIsTyping(true);
            const payload = {
                userId,
                message,
                conversationId: conversationId || null,  // Include conversationId if available
            };
            // Send the user's message to the API
            const res = await chatMessageGPT(payload);

            if (res.data.success) {
                if (!conversationId) {
                    SetConversationId(res.data.message.user_message?.conversation_id);
                }
                const chatgptResponse = res.data.message.chatgpt_response.message;

                // Append ChatGPT's response to the messages
                const botResponse = {
                    position: 'left', // ChatGPT's response on the left
                    type: 'text',
                    text: chatgptResponse, 
                    date: new Date(),
                    // date: new Date(chatgptResponse?.created_at),
                };
                console.log(chatgptResponse, "alpha")
                setMessages((prevMessages) => [...prevMessages, botResponse]);
                console.log(message, "beta");
                setIsTyping(false);
            }
        } catch (error) {
            toast.error('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
            setIsTyping(false);
        }
    };

    const HandleConversationId = async () =>{
        try{
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const payload = {
                userId,
                conversationId: conversationId || null,  
            };
            const res = await oldConversationsHistory(payload);
            if (res.data.success) {
                // Assuming res.data.messages contains the messages array
                const messages = res?.data?.messages?.map((message) => ({
                    position: message?.sender_type === 'user' ? 'right' : 'left',
                    type: 'text',
                    text: message.message,
                    date: new Date(message.created_at),
                }));
                setMessages(messages); // Update the state with the fetched messages
            }
        }catch(error){
            toast.error('Failed to received message. Please try again.');
        }
    }
    useEffect(() => {
        if (conversationIdOld) {
            HandleConversationId(); // Call this function when the component mounts or when conversationIdOld changes
        }
    }, [conversationIdOld]);

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader user={loggedInUser} />
                <div className="chat-messages">
                    {messages?.map((msg, index) => {
                        return (
                            <MessageBox
                                key={index}
                                position={msg.position}
                                type={msg.type}
                                text={msg.text}
                                date={msg.date}
                                // avatar={msg.position === 'left' ? sarah : null} 
                                className={msg.position === 'right' ? 'sent-message' : 'received-message'} // Apply CSS class
                            />
                        )

                    })}
                    {/* Show typing indicator (three dots) when bot is responding */}
                    {isTyping && (
                        <MessageBox
                            position="left" // Typing indicator on the left
                            type="text"
                            text="..." // Three dots as typing indicator
                            date={new Date()}
                            // avatar={sarah} 
                            className="received-message"
                        />
                    )}
                </div>
                <ChatInput onSend={handleSendMessage}  />
            </div>
        </div>
    );
};

export default PreviousChat;
