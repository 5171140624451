import React, { useState, useEffect } from 'react';
import ChatHeader from './ChatHeader';
import ChatSidebar from '../Sidebar/ChatSidebar';
import './ChatModule.css';
import { GetUserProfile } from '../../core/api/apiCalls';
import toast from 'react-hot-toast';
import { Card, CardContent, Typography, Grid, CircularProgress, Box } from "@mui/material";

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    const UserProfile = async () => {
        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const userEmail = user.email;
            setLoading(true);
            const res = await GetUserProfile(userId, userEmail);
            if (res.data.success) {
                toast.success(res.data.message);
                setUserData(res?.data?.user);
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors;
                for (const [key, messages] of Object?.entries(errorMessages)) {

                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        UserProfile();
    }, []);

    const renderGender = (gender) => {
        return gender === "F" ? "Female" : gender === "M" ? "Male" : "";
    };
    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader />
                <div className="chat-messages">
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                            <CircularProgress size={50} />
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                            <Card sx={{ boxShadow: 4, maxWidth: 800, width: "100%", padding: 4 }}>
                                <Box sx={{ backgroundColor: "#1976d2", padding: "10px", borderRadius: "4px 4px 0 0" }}>
                                    <Typography variant="h5" align="center" color="white" sx={{ fontWeight: "bold" }}>
                                        User Profile
                                    </Typography>
                                </Box>
                                <CardContent sx={{ paddingTop: 3 }}>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Name:
                                            </Typography>
                                            <Typography variant="body1">
                                                {userData?.first_name} {userData?.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Email:
                                            </Typography>
                                            <Typography variant="body1">{userData?.email}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Age:
                                            </Typography>
                                            <Typography variant="body1">{userData?.age}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Gender:
                                            </Typography>
                                            <Typography variant="body1">{renderGender(userData?.gender)}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Occupation:
                                            </Typography>
                                            <Typography variant="body1">{userData?.occupation}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Marital Status:
                                            </Typography>
                                            <Typography variant="body1">{userData?.marital_status}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Location:
                                            </Typography>
                                            <Typography variant="body1">{userData?.location}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Today Mode:
                                            </Typography>
                                            <Typography variant="body1">{userData?.user_mood}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Profile;
