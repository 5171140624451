import React, { useState } from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Container,
    LinearProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { registerUser } from '../core/api/apiCalls';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit">Company</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Paper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
}));

const Form = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));

export default function SignUp() {
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [Register, setRegister] = useState(null);
    const handleNavigateLogin = () => {
        navigate('/login');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!event.target.termsAndConditions.checked) {
            return toast.error('Please accept the terms and conditions.');
        }
        const data = new FormData(event.currentTarget);
        const firstName = data.get('firstName');
        const lastName = data.get('lastName');
        const emailValue = data.get('email');
        const passwordValue = data.get('password');
        const confirmPasswordValue = data.get('confirm_password');

        try {
            setLoading(true)
            const res = await registerUser(firstName, lastName, emailValue, passwordValue, confirmPasswordValue);
            if (res.data.success) {
                setRegister(res?.data?.data?.user)
                toast.success(res.data.message);
                navigate('/login');
            }

        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors;
                for (const [key, messages] of Object?.entries(errorMessages)) {

                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false)
        }
    }

    const handleTermsClick = () => {
        window.open('/TermAndCondition', '_blank'); // Opens the terms page in a new tab
      };
    return (
        <Container component="main" maxWidth="sm" style={{
            background: 'white',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            padding: '20px', borderRadius: '22px', height: 'auto', width: '480px'
        }} >
            <CssBaseline />
            <Paper style={{ marginTop: '15px' }}>
                <Avatar sx={{ bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirm_password"
                                label="Confirm Password"
                                type="password"
                                id="confirm_password"
                                autoComplete="confirm_password"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ color: 'black' }}
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                        onClick={handleTermsClick}
                                    />
                                }
                                label="I accept all terms and conditions."
                                name="termsAndConditions"
                            />
                        </Grid>
                    </Grid>
                    <SubmitButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={Loading}
                    >
                        {Loading ? 'loading...' : 'Sign Up'}
                    </SubmitButton>
                    <LinearProgress sx={{ visibility: Loading ? '' : 'hidden' }} />
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link variant="body2" onClick={handleNavigateLogin} style={{ cursor: "pointer" }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </Paper>
            <Box mt={2.5}>
                <Copyright />
            </Box>
        </Container>
    );
}
