import React, { useState, useEffect } from 'react';
import { MessageBox } from 'react-chat-elements';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatSidebar from '../Sidebar/ChatSidebar';
import 'react-chat-elements/dist/main.css';
import './ChatModule.css';
import toast from 'react-hot-toast';
import { chatMessageGPT } from '../../core/api/apiCalls';
import Umeed from '../../Public/Umeed.jpg';
import sarah from '../../Public/sarah.png';



const ChatModule = () => {
    const [messages, setMessages] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);
    const [conversationId, SetConversationId] = useState('');
    const [loggedInUser] = useState({ name: 'John Doe', profileImage: '/path/to/image.jpg' });
    const [isTyping, setIsTyping] = useState(false);
    // Function to send message
    const handleSendMessage = async (message) => {
        // User's message
        const newMessage = {
            position: 'right', // Sent message on the right
            type: 'text',
            text: message,
            date: new Date(),
        };

        // Update UI immediately with user's message
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            setIsTyping(true);
            const payload = {
                userId,
                message,
                conversationId: conversationId || null,  // Include conversationId if available
            };
            // Send the user's message to the API
            const res = await chatMessageGPT(payload);

            if (res.data.success) {
                if (!conversationId) {
                    SetConversationId(res.data.message.user_message?.conversation_id);
                }
                const chatgptResponse = res.data.message.chatgpt_response.message;

                // Append ChatGPT's response to the messages
                const botResponse = {
                    position: 'left', // ChatGPT's response on the left
                    type: 'text',
                    text: chatgptResponse, 
                    date: new Date(),
                    // date: new Date(chatgptResponse?.created_at),
                };
                console.log(chatgptResponse, "alpha")
                setMessages((prevMessages) => [...prevMessages, botResponse]);
                console.log(message, "beta");
                setIsTyping(false);
            }
        } catch (error) {
            toast.error('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
            setIsTyping(false);
        }
    };
    useEffect(() => {
        // Predefined message
        const welcomeMessage = {
            position: 'left', // Always on the right side
            type: 'text',
            text: 'Assalamualaikum, I am Sarah and I am here to help you. You can start conversation in the language that you prefer, Is there something that is bothering you today that you want to talk about? \n السلام علیکم، میں سارہ ہوں اور میں آپ کی مدد کے لیے حاضر ہوں۔ آپ اپنی پسند کی زبان میں گفتگو شروع کر سکتے ہیں، کیا آج کوئی ایسی چیز ہے جو آپ کو پریشان کر رہی ہے جس کے بارے میں آپ بات کرنا چاہتے ہیں؟',
            date: new Date(),
        };
    
        // Add the message to the messages state if it's not already there
        setMessages((prevMessages) => {
            // Check if the message is already present
            if (!prevMessages.some((msg) => msg.text === welcomeMessage.text)) {
                return [...prevMessages, welcomeMessage];
            }
            return prevMessages;
        });
    }, []);

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader user={loggedInUser} />
                <div className="chat-messages">
                    {messages?.map((msg, index) => {
                        
                        return (
                            <MessageBox
                                key={index}
                                position={msg.position}
                                type={msg.type}
                                text={msg.text}
                                date={msg.date}
                                // avatar={msg.position === 'left' ? sarah : null} 
                                className={msg.position === 'right' ? 'sent-message' : 'received-message'} // Apply CSS class
                            />
                        )

                    })}
                    {/* Show typing indicator (three dots) when bot is responding */}
                    {isTyping && (
                        <MessageBox
                            position="left" // Typing indicator on the left
                            type="text"
                            text="..." // Three dots as typing indicator
                            date={new Date()}
                            // avatar={sarah}
                            className="received-message"
                        />
                    )}
                </div>
                <ChatInput onSend={handleSendMessage}  />
            </div>
        </div>
    );
};

export default ChatModule;
