import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { loginUser, GoogleSignIn } from '../core/api/apiCalls';
import { login } from '../core/auth/authSlice';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import { Box, Typography, Grid, Container, Button, LinearProgress } from '@mui/material';
import '../App.css';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit"
            // href="https://mui.com/"
            >
                Company
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [LoadingGoogle, setLoadingGoogle] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const emailValue = data.get('email');
        const passwordValue = data.get('password');


        try {
            setLoading(true)
            const res = await loginUser(emailValue, passwordValue);

            if (res?.status === 200) {
                if (res?.data?.data?.user_type === 'user') {
                    dispatch(
                        login(
                            {
                                token: res?.data?.data?.token,
                                user: res?.data?.data?.user
                            }));
                    if (res.data?.data?.is_profiled === 1) {
                        toast.success(res?.data?.message);
                        navigate('/mood-tracker');

                    } else {
                        toast.success(res?.data?.message);
                        navigate('/profiling');

                    }
                } else if (res?.data?.data?.user_type === 'superadmin') {
                    dispatch(
                        login(
                            {
                                token: res?.data?.data?.token,
                                user: res?.data?.data?.user
                            }));
                    toast.success(res?.data?.message);
                    navigate('/settings');
                }
            }
        } catch (err) {
            console.log('Failed to log in. Please try again.');
            toast.error(err.message);
        } finally {
            setLoading(false)
        }
    };

    const handleNavigateSignUp = () => {
        navigate('/sign-up')
    }

    const HandleClickGoogle = async () => {
        try {
            setLoadingGoogle(true);
            window.location.href = "https://7e0e-203-101-186-55.ngrok-free.app/login/google";
        } catch (err) {
            console.log('Failed to redirect to Google login. Please try again.');
            toast.error(err.message);
        } finally {
            setLoadingGoogle(false)
        }
    }

    // useEffect hook to handle the Google callback
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const authorizationCode = params.get('code');
        const error = params.get('error');

        if (authorizationCode) {
            // Send the code to the backend to exchange for a token and user data
            fetch('https://673a-203-101-186-55.ngrok-free.app/login/google/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: authorizationCode }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data?.success) {
                        // Dispatch login action to store token and user data in Redux
                        dispatch(login({
                            token: data.token,
                            user: data.user
                        }));

                        // Store token and user data in localStorage
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('user', JSON.stringify(data.user));

                        // Redirect based on whether the user is profiled
                        if (data.is_profiled === 1) {
                            toast.success('Successfully logged in with Google!');
                            navigate('/mood-tracker');
                        } else {
                            toast.success('Please complete your profile!');
                            navigate('/profiling');
                        }
                    } else {
                        toast.error('Google login failed');
                        navigate('/login');
                    }
                })
                .catch((error) => {
                    console.error('Error during token exchange:', error);
                    toast.error('Failed to log in with Google');
                    navigate('/login');
                });
        } else if (error) {
            toast.error(`Google login failed: ${error}`);
            navigate('/login');
        }
    }, [location, navigate, dispatch]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="sm" style={{ background: 'white', 
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', 
                borderRadius: '22px', height:'auto', width:'480px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 0.7, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{ color: 'black' }}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0.6 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel style={{ color: '#747373f7' }} control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 1 }}
                            disabled={Loading}
                        >
                            {Loading ? 'login...' : 'Sign In 🔑'}

                        </Button>
                        <LinearProgress sx={{ visibility: Loading ? '' : 'hidden' }} />
                        <Typography style={{ fontWeight: 'bold', fontSize: '14px', color: 'grey' }}> OR</Typography>
                        <Button
                            onClick={HandleClickGoogle}
                            fullWidth
                            variant="contained" color="error"
                            sx={{ mt: 1, mb: 1 }}
                        >
                            <GoogleIcon sx={{ mr: 0.5 }} />
                            {LoadingGoogle ? 'Google...' : 'Sign In Google 🚀'}

                        </Button>
                        <LinearProgress sx={{ visibility: LoadingGoogle ? '' : 'hidden' }} />
                        <Grid container>
                            <Grid item xs>
                                {/* <Link href="#" variant="body2">
                                    Forgot password?
                                </Link> */}
                            </Grid>
                            <Grid item>
                                <Link variant="body2" onClick={handleNavigateSignUp} style={{ cursor: "pointer" }}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 2, mb: 1 }} />
            </Container>
        </ThemeProvider>
    );
}