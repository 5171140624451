import React, { useState } from 'react';
import { Dialog, Box, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { logout } from '../../core/auth/authSlice';
import logo from '../../Public/Umeed.jpg'
import Avatar from '@mui/material/Avatar';
const ChatHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const [openSummaryDialog, setOpenSummaryDialog] = useState(false);

    const handleLogoutClick = () => {
        setOpenLogoutDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenLogoutDialog(false);
    };

    const handleConfirmLogout = () => {
        dispatch(logout())
        navigate('/')
        setOpenLogoutDialog(false);
        toast.success("User has logged out");

    }
    const handleSendReport = () => {
        setOpenSummaryDialog(true)
    }
    const handleCloseSummaryDialog = () => {
        setOpenSummaryDialog(false);
    };

    const handleSendSummeryReport = () => {
        const storedUser = localStorage.getItem('user');
        const user = storedUser ? JSON.parse(storedUser) : {};
        const userId = user.id

        toast.success("this is user id", userId)
    }

    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : {};
    const firstName = user?.first_name || '';
    const lastName = user?.last_name || '';
    const userName = firstName + ' ' + lastName;
    const userType = user?.user_type


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', backgroundColor: '#007bff', color: 'white',height: "7.4%" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        // src={logo}
                        alt={userName}
                        style={{ borderRadius: '50%', width: '40px', height: '40px', marginRight: '10px' }}
                    />
                    <span style={{fontSize:'16px', }}>{userName}</span>
                </div>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', backgroundColor: '#007bff', color: 'white' }}>
                    {/* {userType === 'user' ? null : (
                        <Button
                            onClick={handleSendReport}
                            style={{ color: 'white', background: '#007bff', }}>
                            End Session
                        </Button>
                    )} */}
                    <Button onClick={handleLogoutClick} style={{ color: 'white', background: '#696a6c94' }}>
                        Logout
                    </Button>
                </Box>



                {/* <Button style={{
                        fontSize: '12px',
                        display: 'flex-end',
                        background: '#007bff',
                        width: 'auto',
                        marginTop: '10px',
                        color: 'white',
                    }}
                    // onClick={handleSend}
                    >
                        Create Report</Button> */}

                {/* Logout Confirmation Dialog */}
                <Dialog
                    open={openLogoutDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="logout-dialog-title"
                    aria-describedby="logout-dialog-description"
                >
                    <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to log out?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirmLogout} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Summery Report Confirmation Dialog */}
                <Dialog
                    open={openSummaryDialog}
                    onClose={handleCloseSummaryDialog}
                    aria-labelledby="logout-dialog-title"
                    aria-describedby="logout-dialog-description"
                >
                    <DialogTitle id="logout-dialog-title">Session Report </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Upon clicking the "Yes" button, a summary report for the specified section will be generated.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSummaryDialog} color="primary">
                            No
                        </Button>
                        <Button onClick={handleSendSummeryReport} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>

    );
};

export default ChatHeader;
