import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container maxWidth="md" sx={{ padding: '20px', marginTop: '20px', backgroundColor: '#f4f6f8', borderRadius: '8px' }}>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Disclaimer
                </Typography>
            </Box>

            {/* Introduction Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Introduction
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    Welcome to our chat-based therapy app. This app is designed to provide mental health support and guidance. Please be advised that the information provided through this platform is for general informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment.
                </Typography>
            </Box>

            {/* Not a Substitute for Professional Help Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Not a Substitute for Professional Help
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    The services provided by our app should not be considered a replacement for professional therapy or psychiatric treatment. If you are experiencing a medical or psychological emergency, please contact a qualified healthcare professional or visit the nearest hospital. Our app is not equipped to handle crisis situations, and we cannot guarantee the availability of immediate assistance.
                </Typography>
            </Box>

            {/* Confidentiality Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Confidentiality
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    While we strive to maintain confidentiality and protect your privacy, please be aware that the internet and online communication methods are not entirely secure. We recommend not sharing any highly sensitive or personal information through the chat. By using this app, you acknowledge and accept the potential risks associated with electronic communication.
                </Typography>
            </Box>

            {/* Limitation of Liability Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Limitation of Liability
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    We do not assume responsibility for any decisions made or actions taken based on the information provided through this app. The advice and guidance given by our virtual therapists are not intended to replace personalized care from a licensed mental health professional. We are not liable for any damages, losses, or issues that may arise from using our app.
                </Typography>
            </Box>

            {/* Jurisdiction Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Jurisdiction
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    This app is developed and operated in Pakistan, and its use is governed by the laws of Pakistan. By accessing and using this app, you agree to comply with all applicable local, national, and international laws and regulations.
                </Typography>
            </Box>

            {/* Changes to Disclaimer Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Changes to This Disclaimer
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    We reserve the right to modify or update this disclaimer at any time. Any changes will be posted on this page, and it is your responsibility to review this page periodically to stay informed of any updates.
                </Typography>
            </Box>

            {/* Contact Information Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Contact Information
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    If you have any questions or concerns about this disclaimer, please contact us at [Insert Contact Information].
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsAndConditions;
