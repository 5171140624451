import React from 'react';
import RoutesComponent from './Routes';
import './App.css';
import { Toaster } from 'react-hot-toast';
function App() {
  return (
    <>
    <Toaster />
    <div className="App">
      <header className="App-header">
        <RoutesComponent />
      </header>
    </div>
    </>
    
  );
}

export default App;
