import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    isAuthenticated: !!localStorage.getItem('token'), // Check token in localStorage
    user: JSON.parse(localStorage.getItem('user')) || null,
};

// Create slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            const { token, user } = action.payload;
            state.isAuthenticated = true;
            state.user = user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.clear();
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
